import Zoom from "@mui/material/Zoom";
import styled from "../../../util/styled";

const PREFIX = "ScrollTop";

export const classes = {
  root: `${PREFIX}-root`,
};

export const StyledZoom = styled(Zoom)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  scrollBehavior: "smooth",
}));
