import AppBar from "@mui/material/AppBar";
import styled from "../../../util/styled";

const PREFIX = "Header";

export const classes = {
  container: `${PREFIX}-container`,
  logoWrapper: `${PREFIX}-logoWrapper`,
  logoLink: `${PREFIX}-logoLink`,
  logo: `${PREFIX}-logo`,
  navigation: `${PREFIX}-navigation`,
};

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  [`& .${classes.container}`]: {
    height: 120,
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },

  [`& .${classes.logoWrapper}`]: {
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer",
  },

  [`& .${classes.logo}`]: {
    width: 300,
    height: "auto",
  },

  [`& .${classes.navigation}`]: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
})) as typeof AppBar;
