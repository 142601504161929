import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React from "react";
import theme from "../../../util/theme";
import LoaderOverlay from "../../LoaderOverlay";
import MetaLayout from "../MetaLayout";
import { MainLayoutProps } from "./props";
import { RootStyles } from "./styles";

const MainLayout = (props: MainLayoutProps) => {
  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootStyles>
          <LoaderOverlay />
          <MetaLayout>{props.children}</MetaLayout>
        </RootStyles>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MainLayout;
