import blueGrey from "@mui/material/colors/blueGrey";
import Fab from "@mui/material/Fab";
import styled from "../../../util/styled";

const PREFIX = "MetaLayout";

export const classes = {
  main: `${PREFIX}-main`,
};

export const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: 390,
  backgroundColor: blueGrey[50],

  [`& .${classes.main}`]: {
    width: "100%",
    "& > div:nth-child(even)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const StyledFab = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.innoBlue.main,
  color: theme.palette.innoBlue.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.innoBlue.light,
    color: theme.palette.innoBlue.dark,
  },
}));
