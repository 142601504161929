import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MENU_ITEMS } from "../../../../util/constants";
import { handleNavigate } from "../../../../util";
import { classes, StyledIconButton, StyledSwipeableDrawer } from "./styles";

const MobileMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpen = (event: any) => {
    setMenuOpen(true);
  };
  const handleClose = () => {
    setMenuOpen(false);
  };
  return (
    <React.Fragment>
      <StyledIconButton onClick={handleOpen} aria-label="mobile menu">
        <GiHamburgerMenu />
      </StyledIconButton>

      <StyledSwipeableDrawer
        anchor="top"
        open={menuOpen}
        onClose={handleClose}
        onOpen={() => console.log("SwipeableDrawer > onOpen")}
      >
        <Box
          sx={{
            width: "auto",
          }}
          role="presentation"
          onClick={handleClose}
          onKeyDown={handleClose}
        >
          <List disablePadding>
            {MENU_ITEMS.map(({ text, to }) => (
              <ListItem
                key={text}
                onClick={() => handleNavigate(to)}
                button
                className={classes.mobileItem}
              >
                {text}
              </ListItem>
            ))}
          </List>
        </Box>
      </StyledSwipeableDrawer>
    </React.Fragment>
  );
};

export default MobileMenu;
