import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import styled from "../../../../util/styled";

const PREFIX = "Header";

export const classes = {
  mobileItem: `${PREFIX}-mobileItem`,
};

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  [`& .${classes.mobileItem}`]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    color: theme.palette.innoBlue.contrastText,
    backgroundColor: theme.palette.innoBlue.main,
    transition: "all 2s",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.innoBlue.light,
      color: theme.palette.innoBlue.dark,
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.innoBlue.light,
  borderRadius: 0,
  color: theme.palette.innoBlue.main,
}));
