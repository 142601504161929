import { FooterListItemProps } from "./FooterListItem/props";

export const productsItems: FooterListItemProps[] = [
  {
    text: "Innovance Agenda",
    href: "/agenda",
  },
  {
    text: "Innovance Budget",
    href: "/budget",
  },
  {
    text: "Innovance Métronome",
    href: "/metronome",
  },
];

export const servicesItems: FooterListItemProps[] = [
  {
    text: "La formation",
  },
  {
    text: "L'accompagnement",
  },
  {
    text: "L'assistance téléphonique",
  },
  {
    text: "La maintenance évolutive",
  },
];

export const aboutItems: FooterListItemProps[] = [
  {
    href: "/contact",
    text: "Contact",
  },
  {
    href: "/clients",
    text: "Nos clients",
  },
  {
    text: "Le blog Innovance",
    href: "https://blog.innovance-solutions.com/",
    target: "_blank",
  },
  {
    href: "/mentions-legales",
    text: "Mentions légales",
  },
  {
    href: "/formation/qualite-de-nos-formations",
    text: "Qualité de nos formations",
  },
  {
    href: "/politique-confidentialite-google",
    text: "Politique de confidentialité",
  },
];
