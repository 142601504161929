import amber from "@mui/material/colors/amber";
import blue from "@mui/material/colors/blue";
import deepPurple from "@mui/material/colors/deepPurple";
import lightGreen from "@mui/material/colors/lightGreen";
import orange from "@mui/material/colors/orange";
import pink from "@mui/material/colors/pink";
import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
} from "@mui/material/styles";
import deepMerge from "deepmerge";
import {
  INNO_GREY_DARK,
  INNO_GREY_LIGHT,
  INNO_GREY_MAIN,
  INNO_BLUE_MAIN,
  INNO_BLUE_LIGHT,
  INNO_BLUE_DARK,
} from "./constants";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Palette {
    innoGrey: Palette["primary"];
    innoBlue: Palette["primary"];
    iaPink: Palette["primary"];
    iaBlue: Palette["primary"];
    iaAmber: Palette["primary"];
    ibPurple: Palette["primary"];
    ibGreen: Palette["primary"];
    ibOrange: Palette["primary"];
  }
  interface PaletteOptions {
    innoGrey: PaletteOptions["primary"];
    innoBlue: PaletteOptions["primary"];
    iaPink: PaletteOptions["primary"];
    iaBlue: PaletteOptions["primary"];
    iaAmber: PaletteOptions["primary"];
    ibPurple: PaletteOptions["primary"];
    ibGreen: PaletteOptions["primary"];
    ibOrange: PaletteOptions["primary"];
  }
}

export default createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: "Nunito, sans-serif",
      },
    },
  },
  palette: {
    mode: "light",
    innoGrey: {
      main: INNO_GREY_MAIN,
      light: INNO_GREY_LIGHT,
      dark: INNO_GREY_DARK,
      contrastText: "#fff",
    },
    innoBlue: {
      main: INNO_BLUE_MAIN,
      light: INNO_BLUE_LIGHT,
      dark: INNO_BLUE_DARK,
      contrastText: "#fff",
    },
    iaBlue: {
      main: blue[500],
      dark: blue[900],
      light: blue[100],
      contrastText: "#fff",
    },
    iaPink: {
      main: pink[500],
      dark: pink[900],
      light: pink[100],
      contrastText: "#fff",
    },
    iaAmber: {
      main: amber[500],
      dark: amber[900],
      light: amber[100],
      contrastText: "#000",
    },
    ibPurple: {
      main: deepPurple[500],
      dark: deepPurple[900],
      light: deepPurple[100],
      contrastText: "#fff",
    },
    ibGreen: {
      main: lightGreen[500],
      dark: lightGreen[900],
      light: lightGreen[100],
      contrastText: "#000",
    },
    ibOrange: {
      main: orange[500],
      dark: orange[900],
      light: orange[100],
      contrastText: "#000",
    },
    // error: {
    //   main: "#f03e3e",
    // },
    // warning: {
    //   main: "#f0a04f",
    // },
    // success: {
    //   main: "#4fe054",
    // },
    // text: {
    //   primary: "#343a40",
    //   secondary: "#2e3133",
    //   hint: "#363c42",
    //   disabled: "#48494a",
    // },
    // background: {
    //   default: "#f2f2f2",
    //   paper: "#dce3f2",
    // },
  },
  typography: {
    fontFamily: "Nunito, sans-serif",
  },
});
