import MuiLink from "@mui/material/Link";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { LinkProps } from "./props";

const Link = (props: LinkProps) => {
  const { children, href, ...rest } = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, any>((props, ref) => (
        <GatsbyLink ref={ref} to={href} {...props} />
      )),
    []
  );

  return (
    <MuiLink component={CustomLink} {...rest}>
      {children}
    </MuiLink>
  );
};

export default Link;
