import React from "react";
import clsx from "clsx";
import { classes, StyledRoot } from "./styles";

const LoaderOverlay = () => {
  return (
    <StyledRoot id="page-loader">
      <div className={classes.loader}>
        <div className={clsx(classes.row, classes.spaceBetween)}>
          <div
            className={clsx(classes.square, classes.small, classes.iaPink)}
          ></div>
          <div
            className={clsx(classes.square, classes.large, classes.innoBlue)}
          ></div>
        </div>
        <div className={clsx(classes.row, classes.center)}>
          <div
            className={clsx(classes.square, classes.large, classes.innoGrey)}
          ></div>
        </div>
        <div className={clsx(classes.row, classes.spaceBetween)}>
          <div
            className={clsx(classes.square, classes.small, classes.iaBlue)}
          ></div>
          <div
            className={clsx(classes.square, classes.small, classes.ibGreen)}
          ></div>
        </div>
        <div className={clsx(classes.row, classes.center)}>
          <div
            className={clsx(classes.square, classes.small, classes.ibPurple)}
          ></div>
        </div>
      </div>
    </StyledRoot>
  );
};

export default LoaderOverlay;
