import { Link } from "gatsby";
import React from "react";
import { NavLinkBaseProps } from "./props";
import { classes, StyledLink } from "./styles";

const NavLinkBase = (props: NavLinkBaseProps) => {
  const { to, text, target } = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, any>((props, ref) => (
        <Link
          ref={ref}
          to={to}
          target={target}
          {...props}
          partiallyActive={false}
          activeClassName={classes.active}
        />
      )),
    [to],
  );

  return (
    <StyledLink component={CustomLink} variant="button" underline="none">
      {text}
    </StyledLink>
  );
};

export default NavLinkBase;
