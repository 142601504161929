import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import Link from "../../../../components/Link";
import { classes } from "../styles";
import { FooterListItemProps } from "./props";

const FooterListItem = (props: FooterListItemProps) => {
  const { href, text, target } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ListItem
      className={clsx(classes.listItem, {
        [classes.justifyCenter]: isMobile,
        [classes.alignCenter]: isMobile,
      })}
    >
      <Link
        className={classes.link}
        href={href || "/#"}
        underline="hover"
        color="inherit"
        target={target}
      >
        {text}
      </Link>
    </ListItem>
  );
};

export default FooterListItem;
