import useScrollTrigger from "@mui/material/useScrollTrigger";
import scrollTo from "gatsby-plugin-smoothscroll";
import React from "react";
import { ScrollTopProps } from "./props";
import { StyledZoom } from "./styles";

const ScrollTop = (props: ScrollTopProps) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    scrollTo("#top");
  };

  return (
    <StyledZoom in={trigger}>
      <div onClick={handleClick} role="presentation">
        {children}
      </div>
    </StyledZoom>
  );
};

export default ScrollTop;
