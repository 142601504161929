import { alpha } from "@mui/material/styles";
import styled from "../../../util/styled";

const PREFIX = "Footer";

export const classes = {
  img: `${PREFIX}-img`,
  row: `${PREFIX}-row`,
  rowItem: `${PREFIX}-rowItem`,
  listHeader: `${PREFIX}-listHeader`,
  listItem: `${PREFIX}-listItem`,
  justifyCenter: `${PREFIX}-justifyCenter`,
  alignCenter: `${PREFIX}-alignCenter`,
  divider: `${PREFIX}-divider`,
  link: `${PREFIX}-link`,
  social: `${PREFIX}-social`,
};

export const Root = styled("footer")(({ theme }) => ({
  marginTop: "auto",
  bottom: "0",
  width: "100%",
  color: theme.palette.common.white,

  [`& .${classes.img}`]: {
    width: 200,
    height: "auto",
    marginBottom: theme.spacing(3),
    "&:last-child": {
      marginBottom: 0,
    },
  },

  [`& .${classes.row}`]: {
    margin: theme.spacing(5, 0),
  },

  [`& .${classes.rowItem}`]: {
    "&::nth-of-type(1)": {
      marginBottom: theme.spacing(2),
    },
    "&::nth-of-type(2)": {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.listHeader}`]: {
    fontWeight: 600,
  },

  [`& .${classes.listItem}`]: {
    padding: theme.spacing(0.5, 0),
  },

  [`& .${classes.justifyCenter}`]: {
    justifyContent: "center",
  },

  [`& .${classes.alignCenter}`]: {
    textAlign: "center",
  },

  [`& .${classes.divider}`]: {
    backgroundColor: alpha(theme.palette.innoGrey.light, 0.25),
  },

  [`& .${classes.link}`]: {
    width: "100%",
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.social}`]: {
    margin: theme.spacing(0, 1),
    color: theme.palette.innoBlue.contrastText,
    backgroundColor: theme.palette.innoBlue.main,
    "&:hover": {
      color: theme.palette.innoBlue.dark,
      backgroundColor: theme.palette.innoBlue.light,
    },
  },
}));
