import Link from "@mui/material/Link";
import styled from "../../util/styled";

const PREFIX = "NavLinkBase";

export const classes = {
  active: `${PREFIX}-active`,
};

export const StyledLink = styled(Link)(({ theme }) => ({
  position: "relative",
  display: "flex",
  minHeight: 50,
  alignItems: "center",
  color: theme.palette.innoGrey.main,
  padding: theme.spacing(0, 2),
  margin: theme.spacing(0, 2),
  transition: "all .5s",
  "&:after": {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "0%",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    background: theme.palette.innoBlue.light,
    height: 2,
    textAlign: "left",
    content: "''",
    opacity: 0,
    transition: "all .5s",
  },
  "&:hover": {
    zIndex: 1,
    animation: `color 1s forwards`,
    "&:after": {
      width: "100%",
      zIndex: -10,
      animation: `fill 1s forwards`,
      opacity: 1,
    },
  },

  [`&.${classes.active}`]: {
    color: theme.palette.innoBlue.dark,
    fontWeight: 600,
    "&:after": {
      width: "100%",
      opacity: 1,
    },
    "&:hover": {
      zIndex: 1,
      animation: "none",
      "&:after": {
        animation: `activeFill 0.5s forwards`,
      },
    },
  },

  "@keyframes fill": {
    "0%": {
      width: 0,
      height: 2,
    },
    "50%": {
      width: "100%",
      height: 2,
    },
    "100%": {
      width: "100%",
      height: "100%",
      background: theme.palette.innoBlue.light,
    },
  },
  "@keyframes activeFill": {
    "0%": {
      height: 2,
    },
    "100%": {
      height: "100%",
      background: theme.palette.innoBlue.light,
    },
  },
  "@keyframes color": {
    "70%": {
      // color: theme.palette.innoGrey.main,
      fontWeight: "inherit",
    },
    "73%": {
      fontWeight: 600,
    },
    "100%": {
      color: theme.palette.innoBlue.dark,
      fontWeight: 600,
    },
  },
})) as typeof Link;
