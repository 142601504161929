import React from "react";
import { MdArrowUpward } from "react-icons/md";
import useSiteMetadata from "../../../hooks/useSiteMetadata";
import Footer from "../Footer";
import Header from "../Header";
import ScrollTop from "../ScrollTop";
import { MetaLayoutProps } from "./props";
import { classes, Root, StyledFab } from "./styles";

const MetaLayout = (props: MetaLayoutProps) => {
  const { title } = useSiteMetadata();
  return (
    <Root id="top">
      <Header siteTitle={title} />

      <main className={classes.main}>{props.children}</main>

      <Footer />

      <ScrollTop {...props}>
        <StyledFab aria-label="Haut de page">
          <MdArrowUpward />
        </StyledFab>
      </ScrollTop>
    </Root>
  );
};

export default MetaLayout;
