import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import { handleNavigate } from "../../../util";
import { MENU_ITEMS } from "../../../util/constants";
import NavLink from "../../NavLink";
import NavLinkMenu from "../../NavLink/NavLinkMenu";
import MobileMenu from "./MobileMenu";
import { HeaderProps } from "./props";
import { classes, StyledAppBar } from "./styles";

const Header = (props: HeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledAppBar component="header" position="sticky" elevation={1}>
      <Toolbar className={clsx(classes.container)} component="nav">
        <div
          onClick={() => handleNavigate("/")}
          className={clsx(classes.logoWrapper)}
        >
          <img
            className={clsx({
              [classes.logo]: !isMobile,
            })}
            src="/images/logo-innovance-solutions-logiciels-pour-associations.svg"
            alt="Innovance Solutions"
            width="200"
            height="55"
          />
        </div>

        {isMobile ? (
          <MobileMenu />
        ) : (
          <div className={clsx(classes.navigation)}>
            {MENU_ITEMS.map((item) => (
              <NavLink
                key={item.text}
                to={item.to}
                text={item.text}
                target={item.target}
                children={item.children}
              />
            ))}
          </div>
        )}

        {/* <div>
          <ContactWidget />
        </div> */}
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
