import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import FooterListItem from "../FooterListItem";
import { classes } from "../styles";
import { FooterNavListProps } from "./props";

const FooterNavList = (props: FooterNavListProps) => {
  const { headerText, items } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <List
      dense
      component="div"
      subheader={
        <ListSubheader
          color="inherit"
          component="div"
          disableSticky={true}
          className={clsx(classes.listHeader, {
            [classes.alignCenter]: isMobile,
          })}
        >
          {headerText}
        </ListSubheader>
      }
    >
      {items.map((i) => (
        <FooterListItem {...i} key={i.text} />
      ))}
    </List>
  );
};

export default FooterNavList;
