import { AgendaPriceRangeInfos, IMenuItem, PriceRangeInfos } from "./types";
import React from "react";

export const HEADER = "innovance-solutions-header";
export const FOOTER = "innovance-solutions-footer";
export const HOME_HERO_SECTION = "innovance-solutions-home-section-hero";
export const HOME_SUB_HERO_SECTION =
  "innovance-solutions-home-section-sub-hero";
export const HOME_PRODUCTS_SECTION =
  "innovance-solutions-home-section-products";
export const HOME_SERVICES_SECTION =
  "innovance-solutions-home-section-services";
export const HOME_FACTS_SECTION = "innovance-solutions-home-section-facts";
export const HOME_CUSTOMERS_SECTION =
  "innovance-solutions-home-section-customers";
export const HOME_TESTIMONIALS_SECTION =
  "innovance-solutions-home-section-testimonials";
export const HOME_CONTACT_SECTION = "innovance-solutions-home-section-contact";
export const HOME_AGENDA_ROW = "innovance-solutions-home-produits-agenda";
export const HOME_BUDGET_ROW = "innovance-solutions-home-produits-budget";
export const LEGALS_HERO_SECTION =
  "innovance-solutions-mentions-legales-section-hero";
export const LEGALS_CONTENT_SECTION =
  "innovance-solutions-mentions-legales-section-content";
export const GOOGLE_POLICY_HERO_SECTION =
  "innovance-solutions-politique-confidentialite-google-section-hero";
export const GOOGLE_POLICY_CONTENT_SECTION =
  "innovance-solutions-politique-confidentialite-google-section-content";
export const AGENDA_HERO_SECTION = "innovance-solutions-agenda-section-hero";
export const AGENDA_CAROUSEL_SECTION =
  "innovance-solutions-agenda-section-carousel";
export const AGENDA_FEATURES_SECTION =
  "innovance-solutions-agenda-section-features";
export const AGENDA_MORETOKNOW_SECTION =
  "innovance-solutions-agenda-section-more-to-know";
export const AGENDA_PRICING_SECTION =
  "innovance-solutions-agenda-section-pricing";
export const AGENDA_FEATURE_REPORT =
  "innovance-solutions-agenda-rendre-compte-aux-financeurs";
export const AGENDA_FEATURE_TIME_MANAGEMENT =
  "innovance-solutions-agenda-suivre-les-temps-de-travail";
export const AGENDA_FEATURE_LOAD_PLAN =
  "innovance-solutions-agenda-piloter-un-plan-de-charge";
export const AGENDA_FEATURE_COLLABORATE =
  "innovance-solutions-agenda-collaborer-et-reduire-les-contraintes";
export const AGENDA_FEATURE_COMBINE_IB =
  "innovance-solutions-agenda-repartir-les-charges-salariales-et-indirectes-sur-les-actions";
export const BUDGET_HERO_SECTION = "innovance-solutions-budget-section-hero";
export const BUDGET_FEATURES_SECTION =
  "innovance-solutions-budget-section-features";
export const BUDGET_CAROUSEL_SECTION =
  "innovance-solutions-budget-section-carousel";
export const BUDGET_MORETOKNOW_SECTION =
  "innovance-solutions-budget-section-more-to-know";
export const BUDGET_PRICING_SECTION =
  "innovance-solutions-budget-section-pricing";
export const BUDGET_FEATURE_IMPORT =
  "innovance-solutions-budget-importer-comptabilite-et-paie";
export const BUDGET_FEATURE_FOLLOW =
  "innovance-solutions-budget-suivre-budget-en-temps-reel";
export const BUDGET_FEATURE_COLLABORATE =
  "innovance-solutions-budget-collaborer-et-dialoguer";
export const BUDGET_FEATURE_REPORT =
  "innovance-solutions-budget-rendre-compte-aux-financeurs";
export const FORMATION_QUALITY_HERO_SECTION =
  "innovance-solutions-formation-qualite-hero";
export const FORMATION_QUALITY_INDICATORS_SECTION =
  "innovance-solutions-formation-qualite-indicateurs";
export const FORMATION_QUALITY_CERTIFICATIONS_SECTION =
  "innovance-solutions-formation-qualite-certifications";
export const FORMATION_IA_CYCLE_INDICATORS =
  "innovance-solutions-formation-cycle-agenda-indicateurs";
export const FORMATION_IB_CYCLE_INDICATORS =
  "innovance-solutions-formation-cycle-budget-indicateurs";
export const FORMATION_COMPTA_CYCLE_INDICATORS =
  "innovance-solutions-formation-cycle-comptabilite-indicateurs";
export const COMPANY_HERO_SECTION =
  "innovance-solutions-entreprise-section-hero";
export const COMPANY_MISSION_SECTION =
  "innovance-solutions-entreprise-section-une-mission";
export const COMPANY_CULTURE_SECTION =
  "innovance-solutions-entreprise-section-une-culture";
export const COMPANY_PROFESSIONS_SECTION =
  "innovance-solutions-entreprise-section-des-métiers";
export const COMPANY_JOIN_US_HERO_SECTION =
  "innovance-solutions-nous-rejoindre-section-hero";
export const COMPANY_JOIN_US_JOBS_SECTION =
  "innovance-solutions-nous-rejoindre-section-offres-emploi";
export const COMPANY_JOIN_US_SPONTANEOUS_APPLICATION_SECTION =
  "innovance-solutions-nous-rejoindre-section-candidature-spontanee";
export const QUALIOPI_HREF =
  "https://travail-emploi.gouv.fr/formation-professionnelle/acteurs-cadre-et-qualite-de-la-formation-professionnelle/article/qualiopi-marque-de-certification-qualite-des-prestataires-de-formation";

export const METRONOME_HERO_SECTION =
  "innovance-solutions-metronome-section-hero";
export const METRONOME_PRESENTATION_SECTION =
  "innovance-solutions-metronome-section-presentation";
export const METRONOME_FEATURE_STRUCTURE =
  "innovance-solutions-metronome-structurer-son-budget";
export const METRONOME_FEATURE_MONITORING =
  "innovance-solutions-metronome-suivre-son-budget";
export const METRONOME_FEATURE_CONTROL =
  "innovance-solutions-metronome-controler-son-budget";
export const METRONOME_FEATURE_REPORT =
  "innovance-solutions-metronome-presenter-son-budget";
export const METRONOME_PRICING_SECTION = "innovance-solutions-metronome-tarifs";
export const METRONOME_CAROUSEL_SECTION =
  "innovance-solutions-metronome-section-carousel";
export const CUSTOMERS_SECTION =
  "innovance-solutions-references-clients-section";

export const PHONE_NUMBER = "04 50 10 61 37";
export const INNO_GREY_MAIN = "#87888A";
export const INNO_GREY_LIGHT = "#E1E1E2";
export const INNO_GREY_DARK = "#575859";
export const INNO_BLUE_MAIN = "#0086CB";
export const INNO_BLUE_LIGHT = "#BFE1F2";
export const INNO_BLUE_DARK = "#004B71";
export const MENU_ITEMS: IMenuItem[] = [
  {
    text: "Accueil",
    to: "/",
  },
  {
    text: "Clients",
    to: "/clients",
  },
  // {
  //   text: "L'entreprise",
  //   to: null,
  //   children: [
  //     {
  //       text: "A propos",
  //       to: "/entreprise",
  //     },
  //     {
  //       text: "Nous rejoindre",
  //       to: "/nous-rejoindre",
  //     },
  //   ],
  // },
  {
    text: "Agenda",
    to: "/agenda",
  },
  {
    text: "Budget",
    to: "/budget",
  },
  {
    text: "Métronome",
    to: "/metronome",
  },
  {
    text: "Blog",
    to: "https://blog.innovance-solutions.com/",
    target: "_blank",
  },
  {
    text: "Contact",
    to: "/contact",
  },
];

export const BUDGET_PRICE_RANGES: PriceRangeInfos[] = [
  {
    text: "Association jusqu'à 5 salariés",
    start: 0,
    end: 5,
    totalPrice: 1250,
  },
  {
    text: "Association de 6 à 10 salariés",
    start: 5,
    end: 10,
    totalPrice: 1750,
  },
  {
    text: "Association de 11 à 20 salariés",
    start: 10,
    end: 20,
    totalPrice: 2350,
  },
  {
    text: "Association de 21 à 30 salariés",
    start: 20,
    end: 30,
    totalPrice: 3050,
  },
  {
    text: "Association de 31 à 50 salariés",
    start: 30,
    end: 50,
    totalPrice: 3650,
  },
  {
    text: "Association de 51 à 70 salariés",
    start: 50,
    end: 70,
    totalPrice: 4450,
  },
  {
    text: "Association de 71 salariés et plus",
    start: 70,
    end: Infinity,
    totalPrice: 5390,
  },
];

export const METRONOME_PRICE_RANGES: PriceRangeInfos[] = [
  {
    text: "Budget inférieur à 500 000€",
    start: 0,
    end: 500000 - 1,
    totalPrice: 1550,
  },
  {
    text: "Budget entre 500 000 € et 800 000 €",
    start: 500000,
    end: 800000 - 1,
    totalPrice: 1750,
  },
  {
    text: "Budget entre 800 000 € et 1,2 M€",
    start: 800000,
    end: 1200000 - 1,
    totalPrice: 2300,
  },
  {
    text: "Budget entre 1,2 M€ et 2,6 M€",
    start: 1200000,
    end: 2600000 - 1,
    totalPrice: 2900,
  },
  {
    text: "Budget entre 2,6 M€ et 4 M€",
    start: 2600000,
    end: 4000000 - 1,
    totalPrice: 3500,
  },
  {
    text: "Budget supérieur à 4 M€",
    start: 4000000,
    end: Infinity,
    totalPrice: 4400,
  },
];

export const AGENDA_PRICE_RANGES: AgendaPriceRangeInfos[] = [
  {
    text: "Forfait incluant 5 salariés",
    start: 0,
    end: 5,
    totalPrice: 47,
  },
  {
    text: (
      <React.Fragment>
        du 6<sup>ème</sup> au 10<sup>ème</sup> salarié
      </React.Fragment>
    ),
    start: 5,
    end: 10,
    totalPrice: 0,
    unitPrice: 8.9,
  },
  {
    text: (
      <React.Fragment>
        du 11<sup>ème</sup> au 20<sup>ème</sup> salarié
      </React.Fragment>
    ),
    start: 10,
    end: 20,
    totalPrice: 0,
    unitPrice: 8.1,
  },
  {
    text: (
      <React.Fragment>
        du 21<sup>ème</sup> au 30<sup>ème</sup> salarié
      </React.Fragment>
    ),
    start: 20,
    end: 30,
    totalPrice: 0,
    unitPrice: 7.4,
  },
  {
    text: (
      <React.Fragment>
        du 31<sup>ème</sup> au 50<sup>ème</sup> salarié
      </React.Fragment>
    ),
    start: 30,
    end: 50,
    totalPrice: 0,
    unitPrice: 6.4,
  },
  {
    text: (
      <React.Fragment>
        du 51<sup>ème</sup> au 70<sup>ème</sup> salarié
      </React.Fragment>
    ),
    start: 50,
    end: 70,
    totalPrice: 0,
    unitPrice: 5.8,
  },
  {
    text: (
      <React.Fragment>
        71<sup>ème</sup> salarié et au delà
      </React.Fragment>
    ),
    start: 70,
    end: Infinity,
    totalPrice: 0,
    unitPrice: 4.8,
  },
];
