import { blue, lightGreen, lime, orange } from "@mui/material/colors";
import {
  INNO_BLUE_DARK,
  INNO_BLUE_MAIN,
  INNO_GREY_DARK,
  INNO_GREY_MAIN,
} from "../../../util/constants";
import styled from "../../../util/styled";

export const INNO_BLUE_GRADIENT = `linear-gradient(130deg, ${INNO_BLUE_DARK} 25%, ${INNO_BLUE_MAIN} 75%)`;

export const INNO_GREY_GRADIENT = `linear-gradient(130deg, ${INNO_GREY_DARK} 25%, ${INNO_GREY_MAIN} 75%)`;

export const HOME_HERO_GRADIENT = `linear-gradient(130deg, ${lightGreen[500]} 25%, ${blue[500]} 75%)`;

export const IA_GRADIENT = `linear-gradient(130deg, ${blue[900]} 25%, ${blue[500]} 75%)`;

export const IB_GRADIENT = `linear-gradient(130deg, ${lime[800]} 25%, ${lime[500]} 75%)`;

export const METRONOME_GRADIENT = `linear-gradient(130deg, ${orange[900]} 25%, ${orange[400]} 75%)`;

const PREFIX = "Global";

export const globalClasses = {
  innoBlueGradient: `${PREFIX}-innoBlueGradient`,
  innoGreyGradient: `${PREFIX}-innoGreyGradient`,
  homeHeroGradient: `${PREFIX}-homeHeroGradient`,
  iaGradient: `${PREFIX}-iaGradient`,
  ibGradient: `${PREFIX}-ibGradient`,
  metronomeGradient: `${PREFIX}-metronomeGradient`,
};

export const RootStyles = styled("div")(({ theme }) => ({
  position: "relative",
  [`& .${globalClasses.innoBlueGradient}`]: {
    background: INNO_BLUE_GRADIENT,
  },
  [`& .${globalClasses.innoGreyGradient}`]: {
    background: INNO_GREY_GRADIENT,
  },
  [`& .${globalClasses.homeHeroGradient}`]: {
    background: HOME_HERO_GRADIENT,
  },
  [`& .${globalClasses.iaGradient}`]: {
    background: IA_GRADIENT,
  },
  [`& .${globalClasses.ibGradient}`]: {
    background: IB_GRADIENT,
  },
  [`& .${globalClasses.metronomeGradient}`]: {
    background: METRONOME_GRADIENT,
  },
}));
