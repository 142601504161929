import { blue, lightGreen, lime, pink } from "@mui/material/colors";
import { height } from "@mui/system";
import {
  INNO_BLUE_DARK,
  INNO_BLUE_MAIN,
  INNO_GREY_DARK,
  INNO_GREY_MAIN,
} from "../../util/constants";
import styled from "../../util/styled";

const PREFIX = "LoaderOverlay";

export const classes = {
  loader: `${PREFIX}-loader`,
  row: `${PREFIX}-row`,
  spaceBetween: `${PREFIX}-spaceBetween`,
  center: `${PREFIX}-center`,
  square: `${PREFIX}-square`,
  large: `${PREFIX}-large`,
  small: `${PREFIX}-small`,
  iaPink: `${PREFIX}-iaPink`,
  innoBlue: `${PREFIX}-innoBlue`,
  innoGrey: `${PREFIX}-innoGrey`,
  iaBlue: `${PREFIX}-iaBlue`,
  ibGreen: `${PREFIX}-ibGreen`,
  ibPurple: `${PREFIX}-ibPurple`,
};

export const StyledRoot = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: "100vh",
  margin: "auto",
  backgroundColor: theme.palette.innoGrey.light,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: theme.zIndex.appBar + 100,
  transition: "all 2s",
  [`& .${classes.loader}`]: {
    display: "flex",
    flexDirection: "column",
    width: 250,
    height: 250,
    animation: "spin 5s infinite",
  },

  [`& .${classes.row}`]: {
    display: "flex",
    [`&.${classes.spaceBetween}`]: {
      justifyContent: "space-between",
    },
    [`&.${classes.center}`]: {
      justifyContent: "center",
    },
  },

  [`& .${classes.square}`]: {
    animationName: "disappear",
    animationDirection: "alternate",
    animationDuration: "800ms",
    animationIterationCount: "infinite",
    [`&.${classes.small}`]: {
      width: 50,
      height: 50,
    },
    [`&.${classes.large}`]: {
      width: 75,
      height: 75,
    },
    [`&.${classes.iaPink}`]: {
      backgroundColor: theme.palette.iaPink.main,
      animationDelay: "200ms",
    },
    [`&.${classes.innoBlue}`]: {
      backgroundColor: theme.palette.innoBlue.main,
      animationDelay: "400ms",
    },
    [`&.${classes.innoGrey}`]: {
      backgroundColor: theme.palette.innoGrey.main,
      animationDelay: "600ms",
    },
    [`&.${classes.iaBlue}`]: {
      backgroundColor: theme.palette.iaBlue.main,
      animationDelay: "400ms",
    },
    [`&.${classes.ibGreen}`]: {
      backgroundColor: theme.palette.ibGreen.main,
      animationDelay: "600ms",
    },
    [`&.${classes.ibPurple}`]: {
      backgroundColor: theme.palette.ibPurple.main,
      animationDelay: "800ms",
    },
  },

  "@keyframes disappear": {
    "0%": {
      transform: "scale(1, 1)",
    },
    "100%": {
      backgroundColor: "transparent",
      transform: "scale(0, 0)",
    },
  },

  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "25%": {
      transform: "rotate(90deg)",
    },
    "50%": {
      transform: "rotate(180deg)",
    },
    "75%": {
      transform: "rotate(270deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
